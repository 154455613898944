import React from 'react';
import styles from './footer.module.css';

export default function Footer() {
    return (
        <div style={{marginTop:'30px'}}>
            <h1 style={{margin:'60px 0 20px 0', borderBottom:'1px solid', width:'180px', marginLeft:'auto', marginRight:'auto'}}>Contact Us</h1>
            <h2 style={{margin:'20px 0 20px 0'}}>Have any questions?</h2>
            <a style={{margin:'20px 0 20px 0'}} href="mailto:orders@sicilianlove.ca">Email us at: <span style={{color:'red'}}>orders@sicilianlove.ca</span></a>
            <p style={{margin:'20px 0 20px 0'}}>Please place all orders through our Instagram account&nbsp;<a style={{color:'red'}} href='https://www.instagram.com/sicilianlovefinefoods/'>@sicilianlovefinefoods</a></p>
            <p style={{margin:'20px 0 20px 0'}}>FOLLOW US ON INSTAGRAM</p>
            <a href='https://www.instagram.com/sicilianlovefinefoods/'>@sicilianlovefinefoods</a>
            <p className={styles.footerBottom}>COPYRIGHTS © SICILIANLOVE | ALL RIGHTS RESERVED</p>
        </div>
    )
}
