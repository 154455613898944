import React from 'react';
import { Route, Switch }  from 'react-router-dom';
import Home from './components/home/';
import Products from './components/products/';
import Contact from './components/contact/';
import NotFound from './components/404';

export default function Routes() {
    return(
    <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/products" component={Products} />
        <Route path="/contact" component={Contact} />
        <Route component={NotFound} />
    </Switch>
    )
};
