import React from 'react';
import Footer from '../footer'
import ImgViewer from '../imageViewer';
import FirstImg from '../home/jumbotron/hotG.jpeg';
import ThirdImg from '../home/jumbotron/HotPPaste.jpeg';
import FifthImg from '../home/jumbotron/hotPeppers.jpeg';
import styles from '../footer/footer.module.css';
import EightFire from './eight.png';
import SixFire from './six.png';
import FourFire from './four.png';

export default function Product(){
    return (
        <div>
            <h1 style={{marginBottom:'20px',borderBottom:'1px solid', width:'270px', marginLeft:'auto', marginRight:'auto'}}>OUR PRODUCTS</h1>
            <p className={styles.para}>Our love for piccante (hot), famiglia (family) and amici (friends) inspired us to share our Sicilian Love.
    Sicilian Love came to life years ago through our passion for hot foods. Our products are handmade by season in small batches, preservative-free and the perfect addition to spice up any meal.</p>
            <hr style={{width:'200px', marginLeft:'auto', marginRight:'auto', marginTop:'20px', borderBottom:'1px solid'}}/>
            <div className={styles.imgBox}>
                <div className={styles.productBox}>
                    <ImgViewer images={[FifthImg]}/>
                    <div className={styles.textBox}>
                        <h2 className={styles.textHeader}>Hot Peppers</h2>
                        <img src={EightFire} style={{width:'200px', marginLeft:'5%'}} alt=''/>
                        <p className={styles.textPara}>Our chili peppers are smothered in oil and add fire to your meal. Made with chili peppers, salt, vinegar, vegetable oil and love.</p>
                    </div>
                </div>
                <div className={styles.productBox}>
                    <div className={styles.textBox}>
                        <h2 className={styles.textHeaderInv}>Hot Pepper Paste</h2>
                        <img src={SixFire} className={styles.flames} alt=''/>
                        <p className={styles.textParaInv}>Our paste is a combination of three peppers that will explode your taste buds. Made with shepherd peppers, habanero peppers, scotch bonnet peppers, salt, vegetable oil and love.</p>
                    </div>
                    <ImgViewer images={[ThirdImg]} className={styles.secondPic}/>
                </div>
                <div className={styles.productBox}>
                    <ImgViewer images={[FirstImg]}/>
                    <div className={styles.textBox}>
                        <h2 className={styles.textHeader}>Hot Giardiniera</h2>
                        <img src={FourFire} style={{width:'200px', marginLeft:'5%'}} alt=''/>
                        <p className={styles.textPara}>A great addition to any sandwhich that is in need of a bit of spice. Made with celery, cauliflower, peppers, beans, carrots, onions, cucumbers, olives, tomato paste, vinegar, vegetable oil, salt, pepper and love.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
