import React from 'react';
import styles from './index.module.css';
import Logo from '../../navbar/header/logo-new.png';
import Footer from '../../footer';
import ImgViewer from '../../imageViewer'; 
import FirstImg from './1.JPG';
import SecondImg from './2.JPG';
import ThirdImg from './3.JPG';
import FourthImg from './4.JPG';
import FifthImg from './5.JPG';
import EightFire from '../../products/eight.png';
import SixFire from '../../products/six.png';
import FourFire from '../../products/four.png';
import HotP from './hotPeppers.jpeg';
import HotPP from './HotPPaste.jpeg';
import HotG from './hotG.jpeg';

const Images= [
    FirstImg,SecondImg,ThirdImg,FourthImg,FifthImg
]

export default function Home2 (){
    return (
        <div>
            <div className={styles.mainbox}>
                    <h1 className={styles.header1}>WELCOME TO</h1>
                    <img src={Logo} className={styles.logo} alt="logo"/>
                    <p className={styles.para}>Our love for piccante (hot), famiglia (family) and amici (friends) inspired us to share our Sicilian Love.
                    Sicilian Love came to life years ago through our passion for hot foods. Our products are handmade by season in small batches, preservative-free and the perfect addition to spice up any meal.</p>
            </div>
            {/* <div style={{marginBottom:'30px'}}>
            <a href="/products" style={{borderBottom:'1px solid', width:'220px', marginLeft:'auto',marginTop:'100px', marginRight:'auto', fontSize:'30px'}}>Highlights</a>
                <ImgViewer images={Images}/>
            </div> */}
            <div>
                <a href="/products" style={{borderBottom:'1px solid', width:'220px', marginLeft:'auto',marginTop:'100px', marginRight:'auto', fontSize:'30px'}}>Our Products</a>
                <div className={styles.products} >
                    <a className={styles.productBox} href="/products">
                        <img src={HotP} style={{width:'200px'}} alt=''/>
                        <a style={{fontSize:'24px'}}>Hot Peppers</a>
                        <img src={EightFire} style={{width:'190px', marginLeft:'auto', marginRight:'auto'}} alt=''/>
                    </a>
                    <a className={styles.productBox} href="/products">
                        <img src={HotPP} style={{width:'200px'}} alt=''/>
                        <a style={{fontSize:'23px'}}>Hot Pepper Paste</a>
                        <img src={SixFire} style={{width:'190px', marginLeft:'auto', marginRight:'auto'}} alt=''/>
                    </a>
                    <a className={styles.productBox} href="/products">
                    <img src={HotG} style={{width:'200px'}} alt=''/>
                        <a style={{fontSize:'24px'}}>Hot Giardiniera</a>
                        <img src={FourFire} style={{width:'190px', marginLeft:'auto', marginRight:'auto'}} alt=''/>
                    </a>
                </div>
            </div>
            <Footer/>
        </div>
    );
};