import React from 'react';
import styles from './contact.module.css';

export default function Contact() {
    return (
        <div>
            <div>
                <h2 style={{margin:'20px 0 20px 0'}}>Have any questions?</h2>
                <p style={{margin:'20px 0 20px 0'}}>Email us at </p>
                <a style={{margin:'20px 0 20px 0'}} href="mailto:orders@sicilianlove.ca">Email us at: <span style={{color:'red'}}>orders@sicilianlove.ca</span></a>
                <p style={{margin:'20px 0 20px 0'}}>Please place all orders through our Instagram account&nbsp;<a style={{color:'red'}} href='https://www.instagram.com/sicilianlovefinefoods/'>@sicilianlovefinefoods</a></p>
                <p style={{margin:'20px 0 20px 0'}}>FOLLOW US ON INSTAGRAM</p>
                <a href='https://www.instagram.com/sicilianlovefinefoods/'>@sicilianlovefinefoods</a>
            </div>
            <p className={styles.footerBottom}>COPYRIGHTS © SICILIANLOVE 2021 | ALL RIGHTS RESERVED</p>
        </div>
    )
}
